/*Header is default home page header*/
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding-bottom: 30px;
  padding-top: 30px;
}

.header-img {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  width: 50%;
  height: 10%;
  box-shadow: 0 0 1px 2px rgba(0,0,0,0.1) inset;
  background-color: white;
  margin-left: 25%;
  transition: ease .5s;

}



#logo  {
  width: 100px;
  height: 100px;
}
  


.header-img:hover {
  box-shadow: 0 0 5px 5px rgba(0,0,0,0.1) inset;
}

/* App header refers to all other page headers -> they containe a button to return to home */
.app-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding-bottom: 30px;
  padding-top: 30px;
}


#back-button {
  margin-left: 8%;
  margin-right: 2%;
  width: max(3.5%, 30px);
  height: max(3.5%,30px);
  cursor: pointer;

  border-radius: 10px;
  padding: 9px;
  opacity: .6;
  box-shadow: 0 0 1px 3px rgba(0, 0, 0, 0.1) inset;
  transition: ease .5s;
}

#back-button:hover {
  opacity: 1;
  box-shadow: 0 0 3px 5px rgba(0,0,0,0.1) inset;
}

.app-header-img {
  margin-right: 25%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  width: 50%;
  min-width: 220px;
  height: 10%;
  box-shadow: 0 0 1px 2px rgba(0,0,0,0.1) inset;
  background-color: white;
  transition: box-shadow .5s ease, transform 2s ease-in;
}

.app-header-img:hover {
  box-shadow: 0 0 5px 5px rgba(0,0,0,0.1) inset;
}


.header-img h1, .app-header-img h1 {
  font-size: max(3vw, 33px);
  margin-right: 5%;
}

#logo {
  width: max(8%, 50px);
  height: max(8%, 50px);
  border-radius: 10px;
}

.body {
  min-height: 450px;
  height: 100%;
  border-radius: 25px;
  box-shadow: 0 0 7px 2px rgba(0, 0, 0, 0.1) inset;
  padding: 10px;
  margin-left: 30px;
  margin-right: 30px;
  background-color: white;
  transition: ease .5s;
}


.icon-box {
  min-height: 450px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 25px;
}

.body:hover {
  box-shadow: 0 0 7px 5px rgba(0, 0, 0, 0.1) inset;

}

.icon-container {
  flex: 1 0 calc(20%); 
  text-align: center;
  padding: 30px;
}

.icon-container img {
  width: 160px;
  height: 160px;
  border-radius: 40px;
  box-shadow: 0 0 10px 7px rgba(0,0,0,0.1);
  transition: all .5s ease;
  cursor: pointer;
  margin-bottom: 10px;
}


.icon-container img:hover {
  transform: scale(1.03);
  box-shadow: 0 0 20px 15px rgba(0,0,0,0.1);

}

#juicypika {
  background-color:white;
  color: black;
  padding-bottom: 30px;
}

#error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
}

/* below are part of myanimelist */

.row-mode, .col-mode {
  width: 40px;
  height: 40px;
}

.row-mode {
  background: url('./imgs/nav/row.png') no-repeat;
  background-size:100%;

}

.col-mode {
  background: url('./imgs/nav/col.png') no-repeat;
  background-size:100%;
}

.malbody {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 2%;
  margin-bottom: 20px;
}

/* mal card styles */
.mal-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: -35px;
  margin-bottom: 1%;
}

.mal-header input {
  width: 50%;
  height: 20px;
}

.mal-toggle {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 3%;
  margin-top: 1%;
  padding: 0;
}

.filters {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 15px;
}

.filters select {
  margin-right: 2%;
  width: 80px;
}

.mal-card {
  min-width: 225px;
  max-width: 225px;
  text-align: center;
  padding: 20px;
  border-radius: 25px;
  background-color: rgb(249, 252, 255);
  box-shadow: 0 0 5px 3px rgb(0,0,0,0.1);
  transition: all .5s ease;
  margin-bottom: 5%;
}

.mal-card img {
  width: 225px;
  height: 300px
}


.mal-card:hover {
  transform: scale(1.02);
  box-shadow: 0 0 7px 10px rgb(0,0,0,0.1);
}

.mal-title-small {
  min-height: 40px;
  padding: 0;

}

/* card in side view */
.nocard {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 2%;
  margin-bottom: 20px;
}

.no-card {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  min-width: 100%;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  padding: 5px;
  border-radius: 25px;
  background-color: rgb(249, 252, 255);
  box-shadow: 0 0 5px 3px rgb(0,0,0,0.1);
  margin-bottom: 1%;
  color: black;
  transition: all .5s ease;

}

.no-card:hover {
  transform: scale(1.01);
}

.mal-title-small-nocard {

  width: 400px;
  height: auto;
  margin-right: 2%;
  margin-left: 5%;
  padding: 0;
}

.no-card #mal-score, .no-card #mal-status {
  min-width: 200px;
  max-width: 200px;
  margin-right: 2%;
}

.no-card img {
  margin-left: 4%;
  width: 80px;
  border-radius: 20px;
}
/* end of mal card styles */

@media screen and (max-width: 1014px) {
  .mal-title-small-nocard {
    width: 300px;
  }
  .no-card #mal-score, .no-card #mal-status {

    min-width: 150px;
    max-width: 150px;
    margin-right: 2%;
  }
}

@media screen and (max-width: 792px) {
  .mal-title-small-nocard {
    width: 200px;
  }
}
@media screen and (max-width: 706px) {
  .no-card {
    justify-content: center;
  }
  .mal-title-small-nocard {
    width: 100%;
    margin-right: 10%;
  }
  .no-card #mal-score, .no-card #mal-status {
    display: none;
  }
  .no-card img {
    margin-left: 4%;
    width: 50px;
    border-radius: 20px;
  }
}


#currently-watching {
  display:inline-block;
  width: max(20%, 200px);
  border-radius: 25px;
  font-size: max(1.1vw, 11px);
  box-shadow: 0 0 3px 5px rgba(0, 0, 0, 0.1) inset;
}

#box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: max(2%, 20px);
  margin-top: 1%;
}


/*test modal window styling */
.modalDiv {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  background-color: rgba(91, 112, 131, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  z-index: 20;
  overflow: hidden;
}
.modal {
  width: 90%;
  height: 90%;
  background-color: white;
  border-radius: 25px;
  z-index: 21;
  overflow:scroll;
}

#title-alt {
  display: flex;
  flex-direction: column;
  grid-area: title;
  padding: 20px;
}

#title-alt h2 {
  font-size: max(2vw, 20px);
  margin: 0;
}


#review {
  box-shadow: 0 0 5px 3px rgb(0,0,0,0.1) inset;
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-right: 20px;
  margin-left: 20px;
  border-radius: 25px;
  grid-area: rev;
  transition: all 0.3s ease;
}

#review:hover {
  box-shadow: 0 0 5px 5px rgba(0,0,0,0.1) inset;
}

.anime-info {
  border-radius: 25px;
  display: grid;
  height: 100vh;
  grid-template-columns: 0.2fr 1fr 1fr;
  grid-template-rows: 0.3fr 0.3fr 2.5fr 2.5fr, 1.5fr;
  grid-template-areas:
  "back title title"
  "malscore malscore myscore"
  "image image image"
  "syn syn syn"
  "rev rev rev";
}

#button-div {
  grid-area: back;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: box-shadow 0.3s ease;

}

#anime-button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  max-width: 7%;
  height: auto;
  border-radius: 20px;
  padding: 0;
  margin-right: -4%;
}

#button-div:hover {
  box-shadow: 0 0 5px 5px rgb(0,0,0,0.1) inset;

}

#head-img {
  grid-area: image;
  background-color: rgb(255, 255, 255);
  text-align: center;
}

#head-img img {
  max-height: 100%;
  max-width: 100%;
  padding: 20px;
}

#mal-score-anime, #my-score-anime {
  text-align: center;
  padding: 10px;
}

#mal-score-anime {
  grid-area: malscore;
  background-color:rgb(202, 220, 255);
}

#my-score-anime {
  grid-area: myscore;
  background-color:rgb(175, 192, 225);
}

#misc-info {
  grid-area: misc;
  background-color: blanchedalmond;
  text-align: center;
  font-size: 14px;
  padding: 10px;
}

#misc-info h4 {
  padding: 0;
  margin: 0;
}


#synopsis {
 grid-area: syn;
 padding: 20px;
 box-shadow: 0 0 5px 3px rgb(0,0,0,0.1) inset;
 margin-left: 20px;
 margin-right: 20px;
 border-radius: 20px;
 transition: all 0.3s ease;

}

#synopsis:hover {
  box-shadow: 0 0 5px 5px rgba(0,0,0,0.1) inset;
}


a {
  text-decoration: none;
  color: black;

}


@media screen and (max-width: 739px)   {
  .icon-container img {
    width: 130px;
    height: 130px;
  }

  .mal-header {
    margin-top: -55px;
  }



  .row-mode, .col-mode {
    width: 50px;
    height: 50px;
    margin-top: 10px;
  }
}

@media screen and (max-width: 581px)   {
  .icon-container img {
    width: 100px;
    height: 100px;
    margin-bottom: 0;
  }
  .header-img, .app-header-img {
    box-shadow: none;
  }


  .app-header {
    justify-content: center;
  }

  #back-button {
    margin: 0;
    padding: 0px;
    margin-left: 30px;
    box-shadow: none;
    border-radius: 0;
  }

  #back-button:hover {
    box-shadow: none;
  }

  .app-header-img {
    margin: 0;
    margin-right: 50px;
  }

  #logo {
    padding: 0;
    margin: 0;
    width: 100px;
    border-radius: 50px;
  }
  .header-img:hover, .app-header-img:hover {
    box-shadow: none;
  }

  .header-img h1, .app-header-img h1 {
    display: none;
  }
}

@media screen and (max-width: 420px)   {


  .icon-container {
    padding: 10px;
    margin: 0;
  }
}