.directory {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    border-radius: 25px;
    margin-bottom: 20px;
}

.directory-item {

    border-radius: 25px;
    position: relative;
    padding: 25px;
    max-width: 250px;
    max-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    opacity: 1;
    box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.1);
    transition: opacity 2s, background-color 1s, transform 1s, box-shadow 1s;
    margin-bottom: 30px;
}

.directory-item:hover {
    transform: scale(1.02);
    background-color: rgba(0, 0, 0, 0.3);
    box-shadow: none;
}

.directory-item:hover .region {
    opacity: 0;
}

.region {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px;
    color: white;
    position: absolute;
    top: 3%;
    width: 100%;
    right: 0%;
    border-left: 0;
    border-right: 0;
    z-index: 20;
    font-size: 30px;
}

.wanderer {
    position: relative;
    right: 15px;
    top: 15px;
    width: 310px;
    transition: transform 1s;
}

.fischl {
    width: 270px;
    position: relative;
    right: 30px;
    top: 20px;
    transition: transform 1s;
}

.ayaka {
    transition: transform 1s;
}

.wanderer:hover, .fischl:hover, .ayaka:hover {
    transform: scale(1.2);
}

.genshin-body {
    text-align: center;
    height: 100%;
    border-radius: 25px;
    box-shadow: 0 0 7px 2px rgba(0, 0, 0, 0.1) inset;
    padding: 10px;
    margin-left: 30px;
    margin-right: 30px;
    background-color: white;
    transition: ease .5s;
    margin-bottom: 30px;
}

.user-info {
    box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    /*box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.1);*/
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 10px;
    margin: 20px;
    transition: transform 0.5s;
}

.user-info:hover {
    transform: scale(1.0005);
}

.stat-item {
    font-weight: 700;
    padding: 20px;
    max-height: 90px;
    min-height: 90px;
    text-align: center;
    white-space: nowrap;
    margin-right: 20px;
    border-radius: 25px;
}

.stat-number {
    font-size: 20px;
    color: rgb(0, 0, 0);
}


.genshin-body:hover {
    box-shadow: 0 0 7px 5px rgba(0, 0, 0, 0.1) inset;
  
  }

.char-header {
    text-align: center;
    margin-bottom: 30px;
}

.characters {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    width: 100%
}

.char-card {
    position: relative;
    text-align: center;
    box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.1);
    margin: 0 15px 30px 15px;
    border-radius: 25px;
    transition: transform 0.5s;
    max-width: 150px;
    min-height: 230px;
    max-height: 230px;
    background-color: rgba(234, 234, 234, 0.1);

}
.char-card:hover {
    transform: scale(1.02);
}

.char-img {
    width: 150px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

}

.char-name, .char-level {
    padding: 5px;
    margin: 0;
    font-weight: 600;
}

.char-cons {
    font-weight: 700;
    position: absolute;
    top: -5px;
    left: 100px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border-radius: 25px;
    padding: 10px;
}

